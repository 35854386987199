import { FilterFields, Frame } from 'common/models'
import { createAsyncAction, createStandardAction } from 'typesafe-actions'

export enum FrameType {
  ALL_FRAMES = 'ALL',
  CURR_FRAME = 'CURR',
  DARWIN = 'DARWIN',
  DARWIN_PIPELINE = 'DARWIN_PIPELINE',
  SUPER_ANNOTATE = 'SUPER_ANNOTATE',
  SPATIAL_LABELING = 'SPATIAL_LABELING',
  ENCORD = 'ENCORD',
}

export interface VideoFilterPayload {
  startDate?: string
  endDate?: string
  keywords?: string
  region?: string[]
  doctor?: string[]
  tags?: string[]
  classificationTags?: string[]
}

export interface BaseFramePayload {
  bucketName: string
  assetName: string
  frameType: string
  videoId: string
}

export interface ExtractFramesPayload extends BaseFramePayload {
  startTime: number
  endTime: number
}

export interface VideoPropertyPayload {
  videoId: string
  region?: string
  procedure?: string
  tags?: string[]
  frameIds?: Array<string>
  batch?: string
  export_count?: number
  download_count?: number
  surgery_facility?: string
  surgeon_name?: string
  procedure_type?: string
  classificationTags?: string[]
  is_single_nifti_zip?: boolean
  is_single_stl_zip?: boolean
}

export interface VideoImportLogPayload {
  dateTime: string
  surgeonEmail: string
  videoName: string
  annotatorEmail: string
}

interface DateRangePayload {
  startDate: string
  endDate: string
}

export const fetchVideoSegmentsWithFilter = createAsyncAction(
  'Fetch_Video_Segments_With_Filter__Request',
  'Fetch_Video_Segments_With_Filter__Success',
  'Fetch_Video_Segments_With_Filter__Failure',
)<VideoFilterPayload, any, Error>()

export const fetchVideoBatchesWithFilter = createAsyncAction(
  'Fetch_Video_Batches_With_Filter__Request',
  'Fetch_Video_Batches_With_Filter__Success',
  'Fetch_Video_Batches_With_Filter__Failure',
)<VideoFilterPayload, any, Error>()

export const fetchPublicVideoURL = createAsyncAction(
  'Fetch_Public_Video_URL__Request',
  'Fetch_Public_Video_URL__Success',
  'Fetch_Public_Video_URL__Failure',
)<Partial<ExtractFramesPayload>, any, Error>()

export const extractVideoFrames = createAsyncAction(
  'Extract_Video_Frames__Request',
  'Extract_Video_Frames__Success',
  'Extract_Video_Frames__Failure',
)<ExtractFramesPayload, { videoId: string; frames: Frame[] }, Error>()

export const extractFrames = createAsyncAction(
  'Extract_Frames__Request',
  'Extract_Frames__Success',
  'Extract_Frames__Failure',
)<any, any, Error>()

export const uploadToDarwin = createAsyncAction(
  'Upload_To_Darwin__Request',
  'Upload_To_Darwin__Success',
  'Upload_To_Darwin__Failure',
)<any, any, Error>()

export const uploadDicomAsMP4 = createAsyncAction(
  'Upload_Dicom_As_MP4__Request',
  'Upload_Dicom_As_MP4__Success',
  'Upload_Dicom_As_MP4__Failure',
)<any, any, Error>()

export const uploadDicomsToFlexView = createAsyncAction(
  'Upload_Dicoms_To_FlexView__Request',
  'Upload_Dicoms_To_FlexView__Success',
  'Upload_Dicoms_To_FlexView__Failure',
)<any, any, Error>()

export const uploadToSuperAnnotate = createAsyncAction(
  'Upload_To_Super_Annotate__Request',
  'Upload_To_Super_Annotate__Success',
  'Upload_To_Super_Annotate__Failure',
)<any, any, Error>()

export const uploadToEncord = createAsyncAction(
  'Upload_To_Encord__Request',
  'Upload_To_Encord__Success',
  'Upload_To_Encord__Failure',
)<any, any, Error>()

export const uploadDicomsToEncord = createAsyncAction(
  'Upload_Dicoms_To_Encord__Request',
  'Upload_Dicoms_To_Encord__Success',
  'Upload_Dicoms_To_Encord__Failure',
)<any, any, Error>()

export const batchUploadToEncord = createAsyncAction(
  'Batch_Upload_To_Encord__Request',
  'Batch_Upload_To_Encord__Success',
  'Batch_Upload_To_Encord__Failure',
)<any, any, Error>()

export const getSpatialLabelingAPIToken = createAsyncAction(
  'Get_Spatial_Labeling_API_Token__Request',
  'Get_Spatial_Labeling_API_Token__Success',
  'Get_Spatial_Labeling_API_Token__Failure',
)<void, { access_token: string }, Error>()

export const uploadVideoToSpatialLabelingApp = createAsyncAction(
  'Upload_Video_To_Spatial_Labeling_App__Request',
  'Upload_Video_To_Spatial_Labeling_App__Success',
  'Upload_Video_To_Spatial_Labeling_App__Failure',
)<void, { access_token: string }, Error>()

export const batchUploadToSuperAnnotate = createAsyncAction(
  'Batch_Upload_To_Super_Annotate__Request',
  'Batch_Upload_To_Super_Annotate__Success',
  'Batch_Upload_To_Super_Annotate__Failure',
)<any, any, Error>()

export const batchUploadToDarwin = createAsyncAction(
  'Batch_Upload_To_Darwin__Request',
  'Batch_Upload_To_Darwin__Success',
  'Batch_Upload_To_Darwin__Failure',
)<any, any, Error>()

export const changeVideoAssetDateRange = createStandardAction(
  'Change Video Asset Date Range',
)<DateRangePayload>()

export const editVideoProperty = createAsyncAction(
  'Edit_Video_Property__Request',
  'Edit_Video_Property__Success',
  'Edit_Video_Property__Failure',
)<Partial<VideoPropertyPayload>, any, Error>()

export const addVideoImportLog = createAsyncAction(
  'Add_Video_Import_Log__Request',
  'Add_Video_Import_Log__Success',
  'Add_Video_Import_Log__Failure',
)<any, any, Error>()

export const editVideoTag = createAsyncAction(
  'Edit_Video_Tag__Request',
  'Edit_Video_Tag__Success',
  'Edit_Video_Tag__Failure',
)<Partial<VideoPropertyPayload>, any, Error>()

export const getFilterFields = createAsyncAction(
  'Get_Filter_Fields_Request',
  'Get_Filter_Fields_Success',
  'Get_Filter_Fields_Failure',
)<VideoFilterPayload, FilterFields, Error>()

export const bulkAddTags = createAsyncAction(
  'Bulk_Add_Tags_Request',
  'Bulk_Add_Tags_Success',
  'Bulk_Add_Tags_Failure',
)<
  { videoIds: string[]; tags: string[]; classificationTags: string[] },
  { videoIds: string[]; tags: string[]; classificationTags: string[] },
  Error
>()

export const bulkAddRegion = createAsyncAction(
  'Bulk_Add_Region_Request',
  'Bulk_Add_Region_Success',
  'Bulk_Add_Region_Failure',
)<
  { videoIds: string[]; region: string },
  { videoIds: string[]; region: string },
  Error
>()
