import { VideoSegment } from 'common/models'
import Modal from 'components/Modal'
import TabContainer, { TabPane } from 'components/Tab'
import React from 'react'

import AnalyticsTab from './AnalyticsTab'
import VideoTab from './VideoTab'

interface Props {
  video: VideoSegment
  isVisible: boolean
  toggleVisible: (isVisible: boolean) => void
  activeKey: string
}

const VideoModal: React.FC<Props> = ({
  video,
  isVisible,
  toggleVisible,
  activeKey,
}) => {
  const [isVideo, setIsVideo] = React.useState(false)
  const [isDicomZip, setIsDicomZip] = React.useState(false)
  const [isCSV, setIsCSV] = React.useState(false)
  const [isNifti, setIsNifti] = React.useState(false)
  const [isNiftiZip, setIsNiftiZip] = React.useState(false)
  const [isSTLZip, setIsSTLZip] = React.useState(false)
  const [isSTL, setIsSTL] = React.useState(false)

  const isVideoFile = (video: VideoSegment) => {
    const videoExtensions = ['mp4', 'mov', 'avi', 'mpeg', 'mpg']
    return (
      video.filename &&
      videoExtensions.some(extension =>
        video.filename.toLowerCase().endsWith(extension),
      )
    )
  }

  // Zip file for DICOM files from Gradient Health/Segmend
  const isDicomZipFile = (video: VideoSegment): boolean => {
    return (
      (video.filename?.toLowerCase().endsWith('.zip') &&
        video.uri?.includes('dicom-media')) ??
      false
    )
  }

  const isCSVFile = (video: VideoSegment): boolean => {
    return video.filename?.toLowerCase().endsWith('.csv') ?? false
  }

  const isNiftiFile = (video: VideoSegment): boolean => {
    return (
      (video.filename?.toLowerCase().endsWith('.nii') ||
        // video uploader already ensures it is a .nii.gz file (and not other .gz formats)
        video.filename?.toLowerCase().endsWith('.gz')) ??
      false
    )
  }

  // Zip file for NIFTI files
  const isNiftiZipFile = (video: VideoSegment): boolean => {
    if (!video.filename?.toLowerCase().endsWith('.zip')) return false
    if (!video.uri?.includes('3d-media')) return false

    return video.is_single_nifti_zip === true
  }

  // Zip file for STL files
  const isSTLZipFile = (video: VideoSegment): boolean => {
    if (!video.filename?.toLowerCase().endsWith('.zip')) return false
    if (!video.uri?.includes('3d-media')) return false

    return video.is_single_stl_zip === true
  }

  const isSTLFile = (video: VideoSegment): boolean => {
    return video.filename?.toLowerCase().endsWith('.stl') ?? false
  }

  React.useEffect(() => {
    if (isVideoFile(video)) {
      setIsVideo(true)
    } else {
      setIsVideo(false)
    }
  }, [video])

  React.useEffect(() => {
    if (isDicomZipFile(video)) {
      setIsDicomZip(true)
    } else {
      setIsDicomZip(false)
    }
  }, [video])

  React.useEffect(() => {
    if (isCSVFile(video)) {
      setIsCSV(true)
    } else {
      setIsCSV(false)
    }
  }, [video])

  React.useEffect(() => {
    if (isNiftiZipFile(video)) {
      setIsNiftiZip(true)
    } else {
      setIsNiftiZip(false)
    }
  }, [video])

  React.useEffect(() => {
    if (isNiftiFile(video)) {
      setIsNifti(true)
    } else {
      setIsNifti(false)
    }
  }, [video])

  React.useEffect(() => {
    if (isSTLZipFile(video)) {
      setIsSTLZip(true)
    } else {
      setIsSTLZip(false)
    }
  }, [video])

  React.useEffect(() => {
    if (isSTLFile(video)) {
      setIsSTL(true)
    } else {
      setIsSTL(false)
    }
  }, [video])

  const [activeTab, setActiveTab] = React.useState('')
  if (activeTab === '0') {
    activeKey = '0'
  }
  if (activeTab === '1') {
    activeKey = '1'
  }
  if (activeTab === '2') {
    activeKey = '2'
  }
  if (activeTab === '3') {
    activeKey = '3'
  }
  return (
    <Modal
      visible={isVisible}
      footer={null}
      onCancel={() => toggleVisible(false)}
      width={780}
      zIndex={1}>
      <TabContainer activeKey={activeKey} onChange={setActiveTab}>
        {/* <TabPane tab="Frames" key="0">
          <FrameTab video={video} />
        </TabPane> */}
        <TabPane tab={isVideo ? 'Video' : 'Asset'} key="1">
          <VideoTab
            video={video}
            isVideo={isVideo}
            isDicomZip={isDicomZip}
            isCSV={isCSV}
            isNifti={isNifti}
            isNiftiZip={isNiftiZip}
            isSTLZip={isSTLZip}
            isSTL={isSTL}
            setActiveTab={setActiveTab}
            toggleVisible={toggleVisible}
          />
        </TabPane>
        {/* <TabPane tab="History" key="2">
          <HistoryTab videoId={video._id} />
        </TabPane> */}
        {isVideo && (
          <TabPane tab="Analytics" key="3">
            <AnalyticsTab videoId={video._id} />
          </TabPane>
        )}
      </TabContainer>
    </Modal>
  )
}

export default VideoModal
