/* eslint-disable react/no-unknown-property */
import { CopyFilled, CopyTwoTone } from '@ant-design/icons'
import { Intent } from '@blueprintjs/core'
import { OrbitControls } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import { Button, Spin } from 'antd'
import { Colors } from 'common/colors'
import { Spacing } from 'common/stylings'
import Stack from 'components/Stack'
import Toaster from 'components/Toaster'
import Text from 'components/Typography'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { RootState } from 'store/rootReducer'
import { editVideoCountFlow } from 'store/videoSegments/actions'
import * as THREE from 'three'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'

interface KLIndivSTLViewerProps {
  stlFileUrl: string
  fileName: string
  video: any
}

type Props = KLIndivSTLViewerProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

interface KLIndivSTLModelProps {
  stlFileUrl: string
  onLoad: () => void
  reportError: (msg: string) => void
}

const KLIndivSTLModel: React.FC<KLIndivSTLModelProps> = ({
  stlFileUrl,
  onLoad,
  reportError,
}) => {
  const [geometry, setGeometry] = useState<THREE.BufferGeometry | null>(null)

  useEffect(() => {
    let mounted = true
    fetch(stlFileUrl)
      .then(res => res.arrayBuffer())
      .then(arrayBuffer => {
        const loader = new STLLoader()
        const geom = loader.parse(arrayBuffer)
        if (mounted) {
          setGeometry(geom)
        }
      })
      .catch(err => {
        reportError('Error loading STL file. Please try again later.')
      })
    return () => {
      mounted = false
    }
  }, [stlFileUrl, reportError])

  useEffect(() => {
    if (geometry) {
      geometry.computeBoundingBox()
      if (geometry.boundingBox) {
        const size = new THREE.Vector3()
        geometry.boundingBox.getSize(size)
        const maxDim = Math.max(size.x, size.y, size.z)
        const scaleFactor = maxDim > 0 ? 1 / maxDim : 1
        geometry.scale(scaleFactor, scaleFactor, scaleFactor)
      }
      geometry.center()
      geometry.computeVertexNormals()
      onLoad()
    }
  }, [geometry, onLoad, stlFileUrl])

  if (!geometry) return null

  return (
    <mesh geometry={geometry}>
      <meshLambertMaterial
        color={0xaaaaaa}
        flatShading={true}
        side={THREE.DoubleSide}
      />
    </mesh>
  )
}

const KLIndivSTLViewer: React.FC<Props> = ({
  stlFileUrl,
  fileName,
  video,
  editVideoCount,
}) => {
  const [clickCopy, setClickCopy] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [mounted, setMounted] = useState(false)
  const [_, setLoadedModelsCount] = useState(0)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  useEffect(() => {
    setMounted(true)
    setLoadedModelsCount(0)
    setIsLoading(true)
  }, [stlFileUrl])

  const handleModelLoaded = useCallback(() => {
    setLoadedModelsCount(prev => {
      const newCount = prev + 1
      if (newCount >= 1) {
        setIsLoading(false)
      }
      return newCount
    })
  }, [])

  const toggleAfterCopy = () => {
    setClickCopy(true)
    navigator.clipboard.writeText(video.uri)
    Toaster.show({
      icon: 'tick',
      intent: Intent.SUCCESS,
      message: 'Copied to Clipboard!',
    })
  }

  const renderCopyButton = () =>
    !clickCopy ? (
      <CopyTwoTone
        style={{ marginLeft: '5px', cursor: 'pointer' }}
        onClick={toggleAfterCopy}
      />
    ) : (
      <CopyFilled style={{ marginLeft: '5px' }} />
    )

  const handleDownload = () => {
    if (!isDownloaded) {
      editVideoCount({
        videoId: video._id,
        download_count: video.download_count + 1,
        batch: video.batch,
      })
      setIsDownloaded(true)
    }
  }

  const renderDownloadButton = () => (
    <Stack justifyContent="flex-start">
      <a
        href={stlFileUrl}
        download
        style={{ textDecoration: 'none' }}
        onClick={handleDownload}>
        <Button
          disabled={isDownloaded}
          type="primary"
          style={{ marginTop: '0.5rem', marginBottom: '0.75rem' }}>
          Download 3D Asset
        </Button>
      </a>
    </Stack>
  )

  const renderUserInstructions = () => (
    <div
      style={{
        marginBottom: '0.75rem',
        fontSize: '1rem',
        color: 'black',
      }}>
      Drag your mouse to rotate the view. Use the scroll wheel to zoom in and
      out.
    </div>
  )

  return (
    <Stack vertical gutter={Spacing.MEDIUM}>
      <Stack vertical>
        <Text
          style={{ fontSize: '18px', paddingTop: '12px' }}
          fontSize={16}
          fontWeight="bold">
          {fileName}
        </Text>
        <Text color={Colors.PALE_GREY} fontSize={12}>
          {video.uri}
          {renderCopyButton()}
          {renderDownloadButton()}
        </Text>
        {renderUserInstructions()}
        <div
          style={{
            width: '100%',
            height: '500px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}>
          {isLoading && (
            <div style={{ position: 'absolute', zIndex: 1 }}>
              <Spin tip="Loading STL file..." />
            </div>
          )}
          {mounted && (
            <Canvas
              className="custom-canvas"
              style={{ flex: 1, background: '#f8f8f8' }}
              camera={{ position: [1, 1, 1], fov: 45 }}>
              <hemisphereLight
                color={0x444444}
                groundColor={0xffffff}
                intensity={1.0}
              />
              <ambientLight intensity={0.8} />
              <directionalLight
                color={0xffffff}
                intensity={0.5}
                position={[5, 5, 5]}
              />
              <directionalLight
                color={0xffffff}
                intensity={0.5}
                position={[-5, 5, 5]}
              />
              <directionalLight
                color={0xffffff}
                intensity={0.5}
                position={[5, -5, 5]}
              />
              <directionalLight
                color={0xffffff}
                intensity={0.5}
                position={[-5, -5, 5]}
              />
              <Suspense fallback={null}>
                <KLIndivSTLModel
                  stlFileUrl={stlFileUrl}
                  onLoad={handleModelLoaded}
                  reportError={setErrorMessage}
                />
              </Suspense>
              <OrbitControls />
            </Canvas>
          )}
        </div>
      </Stack>
    </Stack>
  )
}

const mapStateToProps = (state: RootState) => ({})
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ editVideoCount: editVideoCountFlow }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(KLIndivSTLViewer)
