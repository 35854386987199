/* eslint-disable react/no-unknown-property */
import { CopyFilled, CopyTwoTone } from '@ant-design/icons'
import { Intent } from '@blueprintjs/core'
import { OrbitControls } from '@react-three/drei'
import { Canvas, useLoader } from '@react-three/fiber'
import { Button, Spin } from 'antd'
import { Colors } from 'common/colors'
import { Spacing } from 'common/stylings'
import Stack from 'components/Stack'
import Toaster from 'components/Toaster'
import Text from 'components/Typography'
import React, { Suspense, useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { RootState } from 'store/rootReducer'
import { editVideoCountFlow } from 'store/videoSegments/actions'
import * as THREE from 'three'
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader'

interface KLSTLViewerProps {
  stlZipFileUrl: string
  stlAssetFileNames: string[]
  stlUrls: string[]
  fileName: string
  video: any
}

type Props = KLSTLViewerProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

interface KLSTLModelProps {
  stlUrl: string
  onLoad: () => void
  visible: boolean
}

const KLSTLModel: React.FC<KLSTLModelProps> = ({ stlUrl, onLoad, visible }) => {
  const geometry = useLoader(STLLoader, stlUrl)

  useEffect(() => {
    if (geometry) {
      geometry.computeBoundingBox()
      if (geometry.boundingBox) {
        const size = new THREE.Vector3()
        geometry.boundingBox.getSize(size)
        const maxDim = Math.max(size.x, size.y, size.z)
        const scaleFactor = maxDim > 0 ? 1 / maxDim : 1
        geometry.scale(scaleFactor, scaleFactor, scaleFactor)
      }
      geometry.center()
      geometry.computeVertexNormals()
      onLoad()
    }
  }, [geometry, onLoad, stlUrl])

  return (
    <mesh geometry={geometry} visible={visible}>
      <meshLambertMaterial
        color={0xaaaaaa}
        flatShading={true}
        side={THREE.DoubleSide}
      />
    </mesh>
  )
}

const KLSTLViewer: React.FC<Props> = ({
  stlZipFileUrl,
  stlAssetFileNames,
  stlUrls,
  fileName,
  video,
  editVideoCount,
}) => {
  const [clickCopy, setClickCopy] = useState(false)
  const [isDownloaded, setIsDownloaded] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [mounted, setMounted] = useState(false)
  const [modelVisibility, setModelVisibility] = useState<boolean[]>([])
  const [_, setLoadedModelsCount] = useState(0)

  useEffect(() => {
    setMounted(true)
    // Only show the first 2 STL files (or 1 if there is only one)
    setModelVisibility(
      stlUrls.map((_, index) => (stlUrls.length >= 2 ? index < 2 : index < 1)),
    )
    setLoadedModelsCount(0)
    setIsLoading(true)
  }, [stlUrls])

  const handleModelLoaded = useCallback(() => {
    setLoadedModelsCount(prev => {
      const newCount = prev + 1
      if (newCount >= stlUrls.length) {
        setIsLoading(false)
      }
      return newCount
    })
  }, [stlUrls])

  const toggleAfterCopy = () => {
    setClickCopy(true)
    navigator.clipboard.writeText(video.uri)
    Toaster.show({
      icon: 'tick',
      intent: Intent.SUCCESS,
      message: 'Copied to Clipboard!',
    })
  }

  const renderCopyButton = () =>
    !clickCopy ? (
      <CopyTwoTone
        style={{ marginLeft: '5px', cursor: 'pointer' }}
        onClick={toggleAfterCopy}
      />
    ) : (
      <CopyFilled style={{ marginLeft: '5px' }} />
    )

  const handleDownload = () => {
    if (!isDownloaded) {
      editVideoCount({
        videoId: video._id,
        download_count: video.download_count + 1,
        batch: video.batch,
      })
      setIsDownloaded(true)
    }
  }

  const renderDownloadButton = () => (
    <Stack justifyContent="flex-start">
      <a
        href={stlZipFileUrl}
        download
        style={{ textDecoration: 'none' }}
        onClick={handleDownload}>
        <Button
          disabled={isDownloaded}
          type="primary"
          style={{ marginTop: '0.5rem', marginBottom: '0.75rem' }}>
          Download 3D Asset
        </Button>
      </a>
    </Stack>
  )

  const renderUserInstructions = () => (
    <div
      style={{
        marginBottom: '0.75rem',
        fontSize: '1rem',
        color: 'black',
      }}>
      Drag your mouse to rotate the view. Use the scroll wheel to zoom in and
      out.
    </div>
  )

  const toggleAllModels = () => {
    setModelVisibility(prev => {
      const allSelected = prev.every(v => v)
      return prev.map(() => !allSelected)
    })
  }

  const toggleVisibility = (index: number) => {
    setModelVisibility(prev => prev.map((v, i) => (i === index ? !v : v)))
  }

  const renderModelToggles = () => (
    <div
      style={{
        marginBottom: '1rem',
        color: Colors.PALE_GREY,
        fontSize: '14px',
      }}>
      <input
        type="checkbox"
        onChange={toggleAllModels}
        checked={modelVisibility.every(v => v)}
        style={{ marginRight: '0.5rem', cursor: 'pointer' }}
      />
      <label>
        {modelVisibility.every(v => v) ? 'Deselect All' : 'Select All'}
      </label>
      {stlAssetFileNames.map((name, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
            marginBottom: '0.5rem',
          }}>
          <input
            type="checkbox"
            checked={modelVisibility[index] ?? true}
            onChange={() => toggleVisibility(index)}
            style={{ cursor: 'pointer' }}
          />
          <span>{`${index + 1}. ${name}`}</span>
        </div>
      ))}
    </div>
  )

  return (
    <Stack vertical gutter={Spacing.MEDIUM}>
      <Stack vertical>
        <Text
          style={{ fontSize: '18px', paddingTop: '12px' }}
          fontSize={16}
          fontWeight="bold">
          {fileName}
        </Text>
        <Text color={Colors.PALE_GREY} fontSize={12}>
          {video.uri}
          {renderCopyButton()}
          {renderDownloadButton()}
        </Text>
        {!isLoading && stlUrls.length >= 1 && renderModelToggles()}
        {renderUserInstructions()}
        <div
          style={{
            width: '100%',
            height: '500px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'relative',
          }}>
          {isLoading && (
            <div style={{ position: 'absolute', zIndex: 1 }}>
              <Spin tip={`Loading STL files... `} />
            </div>
          )}
          {mounted && (
            <Canvas
              className="custom-canvas"
              style={{ flex: 1, background: '#f8f8f8' }}
              camera={{ position: [1, 1, 1], fov: 45 }}>
              <hemisphereLight
                color={0x444444}
                groundColor={0xffffff}
                intensity={1.0}
              />
              <ambientLight intensity={0.8} />
              <directionalLight
                color={0xffffff}
                intensity={0.5}
                position={[5, 5, 5]}
              />
              <directionalLight
                color={0xffffff}
                intensity={0.5}
                position={[-5, 5, 5]}
              />
              <directionalLight
                color={0xffffff}
                intensity={0.5}
                position={[5, -5, 5]}
              />
              <directionalLight
                color={0xffffff}
                intensity={0.5}
                position={[-5, -5, 5]}
              />
              <Suspense fallback={null}>
                {stlUrls.map((url, index) => (
                  <KLSTLModel
                    key={index}
                    stlUrl={url}
                    onLoad={handleModelLoaded}
                    visible={modelVisibility[index]}
                  />
                ))}
              </Suspense>
              <OrbitControls />
            </Canvas>
          )}
        </div>
      </Stack>
    </Stack>
  )
}

const mapStateToProps = (state: RootState) => ({})
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ editVideoCount: editVideoCountFlow }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(KLSTLViewer)
