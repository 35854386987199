import axios from 'axios'
import EntryType, { PropertyType } from 'common/entryType'
import { FilterFields } from 'common/models'
import { FileItem } from 'components/ZipViewer/KLZipFileViewer'
import { get } from 'lodash'
import _ from 'lodash'
import { stringify } from 'query-string'
import { Dispatch } from 'redux'
import { addVideoHistoryEntryFlow } from 'store/history/actions'
import {
  getHistoryActionFromRegion,
  getHistoryTypeFromRegion,
} from 'store/history/selectors'
import { showModalFlow } from 'store/modal/actions'
import { getTimeRangeString } from 'utilities/datetime'
import { parseHTTPError } from 'utilities/error'

import {
  addVideoImportLog,
  batchUploadToDarwin,
  batchUploadToEncord,
  batchUploadToSuperAnnotate,
  bulkAddRegion,
  bulkAddTags,
  editVideoProperty,
  editVideoTag,
  extractFrames,
  ExtractFramesPayload,
  extractVideoFrames,
  fetchPublicVideoURL,
  fetchVideoBatchesWithFilter,
  fetchVideoSegmentsWithFilter,
  getFilterFields,
  getSpatialLabelingAPIToken,
  uploadDicomAsMP4,
  uploadDicomsToEncord,
  uploadDicomsToFlexView,
  uploadToDarwin,
  uploadToEncord,
  uploadToSuperAnnotate,
  uploadVideoToSpatialLabelingApp,
  VideoFilterPayload,
  VideoPropertyPayload
} from './actionCreators'

export const fetchVideoSegmentsFilterFlow = (
  page = 1,
  payload: VideoFilterPayload = {},
) => (dispatch: Dispatch) => {
  dispatch(fetchVideoSegmentsWithFilter.request(payload))
  axios
    .get(`/video/filter?${stringify({ page, ...payload })}`)
    .then(res => {
      dispatch(fetchVideoSegmentsWithFilter.success(res))
    })
    .catch(err => dispatch(fetchVideoSegmentsWithFilter.failure(err)))
}

export const fetchVideoBatchesFilterFlow = (
  page = 1,
  payload: VideoFilterPayload = {},
  numOfSentVideos = 0,
) => (dispatch: Dispatch) => {
  dispatch(fetchVideoBatchesWithFilter.request(payload))
  axios
    .get(`/video/batches?${stringify({ page, ...payload, numOfSentVideos })}`)
    .then(res => {
      dispatch(fetchVideoBatchesWithFilter.success(res))
    })
    .catch(err => dispatch(fetchVideoBatchesWithFilter.failure(err)))
}

export const fetchPublicVideoURLFlow = (
  params: Partial<ExtractFramesPayload>,
  videoId: string,
  batch: string,
) => (dispatch: Dispatch) => {
  dispatch(fetchPublicVideoURL.request(params))
  axios
    .get(`/video/get-public-url?${stringify(params)}`)
    .then(res => {
      const resPayload = {
        publicURL: get(res, ['data', 'publicURL'], ''),
        videoId,
        batch,
      }
      dispatch(fetchPublicVideoURL.success(resPayload))
    })
    .catch(err => dispatch(fetchPublicVideoURL.failure(err)))
}

export const extractFramesFlow = (params: any) => (dispatch: Dispatch) => {
  dispatch(extractFrames.request(params))
  axios
    .get(`/video/extract-frames?${stringify(params)}`)
    .then(res => {
      dispatch(extractFrames.success(res.data))
      showModalFlow('SUCCESS', {
        isOpen: true,
        message: 'Video exported to Darwin successfully!',
      })(dispatch)
    })
    .catch(err => {
      dispatch(extractFrames.failure(err))
      const error = parseHTTPError(err)
      showModalFlow('ERROR', {
        isOpen: true,
        message: error.message,
        status: error.status,
      })(dispatch)
    })
}

export const extractToDarwinFlow = (params: any) => (dispatch: Dispatch) => {
  dispatch(uploadToDarwin.request(params))
  axios
    .post("/video/upload-to-darwin", params)
    .then(res => {
      dispatch(uploadToDarwin.success(res.data))
      const videoURI = params.videoURI.split('/')
      const fileName = videoURI.pop()
      showModalFlow('SUCCESS', {
        isOpen: true,
        message: `${fileName} exported to Darwin successfully!`,
      })(dispatch)
    })
    .catch(err => {
      dispatch(uploadToDarwin.failure(err))
      const error = parseHTTPError(err)
      showModalFlow('ERROR', {
        isOpen: true,
        message: error.message,
        status: error.status,
      })(dispatch)
    })
}

export const uploadDicomsToEncordFlow =
  (
    dicomURIs: string[],
    destinationFolderEncord: string,
    commonSeriesDescription: string,
    videoTags: string[],
    videoDoctor: string,
    videoRegion: string,
    radiologyReportText: string
  ) =>
  (dispatch: Dispatch) => {
    const payload = {
      input_files_presigned_urls: dicomURIs,
      destinationFolderEncord,
      commonSeriesDescription,
      videoTags,
      videoDoctor,
      videoRegion,
      radiologyReportText
    };

    dispatch(uploadDicomsToEncord.request(payload));

    axios
      .post("/video/upload-dicoms-to-encord", payload)
      .then((response) => {
        const status = _.get(response, "data.status", "");
        const dicomName = _.get(response, "data.dicomName", "");

        dispatch(uploadDicomsToEncord.success(response.data));

        if (status === "DONE" || status === "PENDING") {
          showModalFlow("SUCCESS", {
            isOpen: true,
            message: `DICOMS uploaded to Encord successfully! The file name is ${dicomName}.`,
          })(dispatch);
        } else {
          showModalFlow("ERROR", {
            isOpen: true,
            message: "Upload failed due to an error.",
          })(dispatch);
        }
      })
      .catch((err) => {
        dispatch(uploadDicomsToEncord.failure(err));

        const error = parseHTTPError(err);

        showModalFlow("ERROR", {
          isOpen: true,
          message: error.message,
          status: error.status,
        })(dispatch);
      });
  };


export const uploadDicomAsMP4Flow = (
  dicomURIs: string[],
  destinationProjectSuperAnnotate: string,
  commonSeriesDescription: string,
  videoTags: string[],
  videoDoctor: string,
  videoRegion: string
) => (dispatch: Dispatch) => {
  const payload = {
    input_files_presigned_urls: dicomURIs,
    destinationProjectSuperAnnotate: destinationProjectSuperAnnotate,
    commonSeriesDescription: commonSeriesDescription,
    videoTags: videoTags,
    videoDoctor: videoDoctor,
    videoRegion: videoRegion,
  };

  dispatch(uploadDicomAsMP4.request(payload));

  axios
    .post("/video/upload-dicoms-as-mp4", payload)
    .then((response) => {
      const isSuccess = _.get(
        response,
        "data.isDicomUploadedAsMP4Successfully",
        false
      );
      const outputVideoName = _.get(response, "data.outputVideoName", "");

      dispatch(uploadDicomAsMP4.success(response.data));

      if (isSuccess) {
        showModalFlow("SUCCESS", {
          isOpen: true,
          message: `Dicoms uploaded as .mp4 successfully! The video name is ${outputVideoName}.`,
        })(dispatch);
      } else {
        showModalFlow("ERROR", {
          isOpen: true,
          message: "Dicom not uploaded as mp4 file",
        })(dispatch);
      }
    })
    .catch((err) => {
      dispatch(uploadDicomAsMP4.failure(err));
      const error = parseHTTPError(err);
      showModalFlow("ERROR", {
        isOpen: true,
        message: error.message,
        status: error.status,
      })(dispatch);
    });
};

export const uploadDicomsToFlexViewFlow = (
  dicomURIs: string[],
  metadata: Partial<FileItem> | null
) => (dispatch: Dispatch) => {
  const payload = {
    dicomURIs,
    metadata: metadata || {},
  };

  dispatch(uploadDicomsToFlexView.request(payload));

  axios
    .post("/video/upload-dicoms-to-flexview", payload)
    .then((response) => {
      const isSuccess = _.get(
        response,
        "data.isDicomsUploadedToFlexViewSuccessfully",
        false
      );

      dispatch(uploadDicomsToFlexView.success(response.data));

      if (isSuccess) {
        const metadataDetails = `
          <h3 style="color: #007bff; font-size: 18px; margin-bottom: 10px;">Study Level Information</h3>
          <p>Patient Name: ${metadata?.patientName || "N/A"}</p>
          <p>MRN: ${metadata?.patientID || "N/A"}</p>
          <p>Study Date: ${metadata?.studyDate || "N/A"}</p>
          <p>Study Description: ${metadata?.studyDescription || "N/A"}</p>
          <p>Accession Number: ${metadata?.accessionNumber || "N/A"}</p>
          
          <h3 style="color: #007bff; font-size: 18px; margin-top: 20px; margin-bottom: 10px;">Series Level Information</h3>
          <p>Series Description: ${metadata?.seriesDescription || "N/A"}</p>
          <p>Series Number: ${metadata?.seriesNumber || "N/A"}</p>
          <p>Modality: ${metadata?.modality || "N/A"}</p>
          <p>Number of Instances: ${metadata?.numStudyInstances || "N/A"}</p>
        `;
      
        showModalFlow("SUCCESS", {
          isOpen: true,
          message: `
            <div style="margin-bottom: 10px; font-size: 18px; color: #333;">
              Dicoms uploaded to FlexView successfully!
            </div>
            ${metadataDetails}
            <div style="margin-top: 10px; font-size: 18px;">
              Please login to <a href="https://app.flexview.ai/login/" 
              target="_blank" 
              rel="noopener noreferrer" 
              style="color: #007bff; font-weight: bold; text-decoration: underline;">FlexView</a> to view them.
            </div>
          `,
        })(dispatch);
      }
       else {
        showModalFlow("ERROR", {
          isOpen: true,
          message: "Failed to upload DICOMs to FlexView.",
        })(dispatch);
      }
    })
    .catch((err) => {
      dispatch(uploadDicomsToFlexView.failure(err));
      const error = parseHTTPError(err);
      showModalFlow("ERROR", {
        isOpen: true,
        message: error.message,
        status: error.status,
      })(dispatch);
    });
};

export const uploadToSuperAnnotateFlow =
  (params: any) => (dispatch: Dispatch) => {
    dispatch(uploadToSuperAnnotate.request(params))
    axios
      .post("/video/upload-to-super-annotate", params)
      .then(res => {
        dispatch(uploadToSuperAnnotate.success(res.data))
        const videoURI = params.videoURI.split('/')
        const fileName = videoURI.pop()
        showModalFlow('SUCCESS', {
          isOpen: true,
          message: `${fileName} exported to Super Annotate successfully!`,
        })(dispatch)
      })
      .catch(err => {
        dispatch(uploadToSuperAnnotate.failure(err))
        const error = parseHTTPError(err)
        showModalFlow('ERROR', {
          isOpen: true,
          message: error.message,
          status: error.status,
        })(dispatch)
      })
  }

export const uploadToEncordFlow = (params: any) => (dispatch: Dispatch) => {
  dispatch(uploadToEncord.request(params))
  axios
    .post("/video/upload-to-encord", params)
    .then(res => {
      dispatch(uploadToEncord.success(res.data))
      const videoURI = params.videoURI.split('/')
      const fileName = videoURI.pop()
      showModalFlow('SUCCESS', {
        isOpen: true,
        message: `${fileName} exported to Encord successfully!`,
      })(dispatch)
    })
    .catch(err => {
      dispatch(uploadToEncord.failure(err))
      const error = parseHTTPError(err)
      showModalFlow('ERROR', {
        isOpen: true,
        message: error.message,
        status: error.status,
      })(dispatch)
    })
}

export const batchUploadToEncordFlow = (params: any) => (
  dispatch: Dispatch
) => {
  dispatch(batchUploadToEncord.request(params))
  axios
    .post("/video/batch-upload-to-encord", params)
    .then(res => {
      dispatch(batchUploadToEncord.success(res.data))
      showModalFlow('SUCCESS', {
        isOpen: true,
        message: 'Videos exported to Encord successfully!',
      })(dispatch)
    })
    .catch(err => {
      dispatch(batchUploadToEncord.failure(err))
      const error = parseHTTPError(err)
      showModalFlow('ERROR', {
        isOpen: true,
        message: error.message,
        status: error.status,
      })(dispatch)
    })
}

export const getSpatialLabelingAPITokenFlow = () => {
  return (dispatch: Dispatch) => {
    dispatch(getSpatialLabelingAPIToken.request())

    return axios
      .post('/video/get-spatial-labeling-api-token')
      .then(res => {
        const { access_token } = res.data
        dispatch(getSpatialLabelingAPIToken.success({ access_token }))
        return access_token
      })
      .catch(err => {
        dispatch(getSpatialLabelingAPIToken.failure(err))
        const error = parseHTTPError(err)

        showModalFlow('ERROR', {
          isOpen: true,
          message: error.message,
          status: error.status,
        })(dispatch)

        throw error
      })
  }
}

export const uploadVideoToSpatialLabelingAppFlow =
  (
    accessToken: (dispatch: Dispatch) => Promise<any>,
    projectName: string,
    videoName: string,
    videoURI: string,
    fps: number,
    region: string,
    surgeon: string,
    videoTags: string[]
  ) =>
  (dispatch: Dispatch): Promise<any> => {
    dispatch(uploadVideoToSpatialLabelingApp.request())

    const payload = {
      accessToken,
      projectName,
      videoName,
      videoURI,
      fps,
      region,
      surgeon,
      videoTags
    }

    return axios
      .post('/video/upload-video-to-spatial-labeling-app', payload)
      .then(res => {
        dispatch(uploadVideoToSpatialLabelingApp.success(res.data))

        showModalFlow('SUCCESS', {
          isOpen: true,
          message: `Video exported to Spatial Labeling App successfully!`,
        })(dispatch)

        return res.data
      })
      .catch(err => {
        dispatch(uploadVideoToSpatialLabelingApp.failure(err))

        const error = parseHTTPError(err)

        showModalFlow('ERROR', {
          isOpen: true,
          message: error.message,
          status: error.status,
        })(dispatch)

        return Promise.reject(error)
      })
  }

export const batchUploadToSuperAnnotateFlow = (params: any) => (
    dispatch: Dispatch
  ) => {
    dispatch(batchUploadToSuperAnnotate.request(params))
    axios
      .post("/video/batch-upload-to-super-annotate", params)
      .then(res => {
        dispatch(batchUploadToSuperAnnotate.success(res.data))
        showModalFlow('SUCCESS', {
          isOpen: true,
          message: 'Videos exported to Super Annotate successfully!',
        })(dispatch)
      })
      .catch(err => {
        dispatch(batchUploadToSuperAnnotate.failure(err))
        const error = parseHTTPError(err)
        showModalFlow('ERROR', {
          isOpen: true,
          message: error.message,
          status: error.status,
        })(dispatch)
      })
  }

export const batchUploadToDarwinFlow = (params: any) => (
  dispatch: Dispatch
) => {
  dispatch(batchUploadToDarwin.request(params))
  axios
    .post("/video/batch-upload-to-darwin", params)
    .then(res => {
      dispatch(batchUploadToDarwin.success(res.data))
      showModalFlow('SUCCESS', {
        isOpen: true,
        message: 'Videos exported to Darwin successfully!',
      })(dispatch)
    })
    .catch(err => {
      dispatch(batchUploadToDarwin.failure(err))
      const error = parseHTTPError(err)
      showModalFlow('ERROR', {
        isOpen: true,
        message: error.message,
        status: error.status,
      })(dispatch)
    })
}

export const getVideoFramesFlow = (
  params: ExtractFramesPayload,
  currUserId: string,
  // setActiveTab: React.Dispatch<React.SetStateAction<string>>,
) => (dispatch: Dispatch) => {
  dispatch(extractVideoFrames.request(params))
  axios
    .get(`/video/get-frames?${stringify(params)}`)
    .then(res => {
      dispatch(
        extractVideoFrames.success({
          videoId: params.videoId,
          frames: res.data['frames'],
        }),
      )
      // setActiveTab('0')
      addVideoHistoryEntryFlow({
        userId: currUserId,
        videoId: params.videoId,
        action: `extracted frames of time range ${getTimeRangeString(
          params.startTime,
          params.endTime,
        )}`,
        type: EntryType.EXTRACT_FRAMES,
      })(dispatch)
    })
    .catch(err => {
      dispatch(extractVideoFrames.failure(err))
      const error = parseHTTPError(err)
      showModalFlow('ERROR', {
        isOpen: true,
        message: error.message,
        status: error.status,
      })(dispatch)
    })
}

export const editVideoPropertyFlow = (
  payload: VideoPropertyPayload,
  currUserId: string,
  dateRange: { startDate: string; endDate: string },
  property: PropertyType,
  oldValue = '',
  newValue = '',
) => (dispatch: Dispatch) => {
  dispatch(editVideoProperty.request(payload))
  axios
    .patch('/video/property', payload)
    .then(res => {
      dispatch(editVideoProperty.success(res))
      getVideoFilterFieldsFlow(dateRange)(dispatch)
      const type = getHistoryTypeFromRegion(property)
      const action = getHistoryActionFromRegion(type, oldValue, newValue)
      addVideoHistoryEntryFlow({
        userId: currUserId,
        videoId: payload.videoId,
        action,
        type,
      })(dispatch)
    })
    .catch(err => dispatch(editVideoProperty.failure(err)))
}

export const editVideoTagFlow = (
  payload: VideoPropertyPayload,
  currUserId: string,
  dateRange: { startDate: string; endDate: string },
) => (dispatch: Dispatch) => {
  dispatch(editVideoTag.request(payload))
  axios
    .patch('/video/property', payload)
    .then(res => {
      dispatch(editVideoTag.success(res))
      getVideoFilterFieldsFlow(dateRange)(dispatch)
      /*
      addVideoHistoryEntryFlow({
        userId: currUserId,
        videoId: payload.videoId,
        action: getHistoryActionFromTagChange(type, tag),
        type,
      })(dispatch)
      */
    })
    .catch(err => dispatch(editVideoTag.failure(err)))
}

export const editVideoTagFlowIndiv = (
  payload: VideoPropertyPayload,
  currUserId: string,
  dateRange: { startDate: string; endDate: string },
) => (dispatch: Dispatch) => {
  dispatch(editVideoProperty.request(payload))
  axios
    .patch('/video/property', payload)
    .then(res => {
      dispatch(editVideoProperty.success(res))
      getVideoFilterFieldsFlow(dateRange)(dispatch)
      /*
      addVideoHistoryEntryFlow({
        userId: currUserId,
        videoId: payload.videoId,
        action: getHistoryActionFromTagChange(type, tag),
        type,
      })(dispatch)
      */
    })
    .catch(err => dispatch(editVideoProperty.failure(err)))
}

export const editVideoCountFlow = (payload: VideoPropertyPayload) => (
  dispatch: Dispatch,
) => {
  dispatch(editVideoProperty.request(payload))
  axios
    .patch('/video/property', payload)
    .then(res => {
      dispatch(editVideoProperty.success(res))
    })
    .catch(err => dispatch(editVideoProperty.failure(err)))
}

export const addVideoImportLogFlow = (payload: any) => (dispatch: Dispatch) => {
  dispatch(addVideoImportLog.request(payload))
  axios
    .post('/video/logs', payload)
    .then(res => {
      dispatch(addVideoImportLog.success(res))
    })
    .catch(err => dispatch(addVideoImportLog.failure(err)))
}

export const getVideoFilterFieldsFlow = (payload: VideoFilterPayload = {}) => (
  dispatch: Dispatch,
) => {
  dispatch(getFilterFields.request(payload))
  axios
    .get(`/video/filter-fields?${stringify(payload)}`)
    .then(res => {
      const filterFields: FilterFields = res.data
      dispatch(getFilterFields.success(filterFields))
    })
    .catch(err => dispatch(getFilterFields.failure(err)))
}

export const bulkAddTagsFlow = (payload: {
  videoIds: string[]
  tags: string[]
  classificationTags: string[]
}) => (dispatch: Dispatch) => {
  dispatch(bulkAddTags.request(payload))
  axios
    .patch('/video/tag/bulk', payload)
    .then(() => {
      dispatch(bulkAddTags.success(payload))
      showModalFlow('SUCCESS', {
        isOpen: true,
        message: 'Added tags successfully!',
      })(dispatch)
    })
    .catch(err => {
      dispatch(bulkAddTags.failure(err))
      showModalFlow('ERROR', {
        isOpen: true,
        message: 'Failed to add tags',
        status: 500,
      })(dispatch)
    })
}

export const bulkAddRegionFlow = (payload: {
  videoIds: string[]
  region: string
}) => (dispatch: Dispatch) => {
  dispatch(bulkAddRegion.request(payload))
  axios
    .patch('/video/region/bulk', payload)
    .then(() => {
      dispatch(bulkAddRegion.success(payload))
      showModalFlow('SUCCESS', {
        isOpen: true,
        message: 'Added region successfully!',
      })(dispatch)
    })
    .catch(err => {
      dispatch(bulkAddRegion.failure(err))
      showModalFlow('ERROR', {
        isOpen: true,
        message: 'Failed to add region',
        status: 500,
      })(dispatch)
    })
}
