export const MAIN = '/'

export const RAW_DATA = '/raw-data'
export const MODEL_VERSION = '/model-version/:versionId'
export const SETTINGS = '/settings'
export const ANALYTICS = '/analytics'
export const QA = '/QA'
export const DATA_LINEAGE = '/data-lineage'
export const SME_VIDEO_ASSETS = '/sme-video-assets'
export const DICOM_ASSETS = '/dicom-assets'
export const THREED_ASSETS = '/3d-assets'
