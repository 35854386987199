import { useAuth0 } from '@auth0/auth0-react'
import { Icon } from '@blueprintjs/core'
import { Divider, Dropdown } from 'antd'
import logo from 'assets/media/KaliberAI-logo.svg'
import { GREY } from 'common/colors'
import { Spacing } from 'common/stylings'
import Logo from 'components/Logo'
import Menu, { MenuItem } from 'components/Menu'
import Stack from 'components/Stack'
import { Text } from 'components/Typography'
import RestoreVideoColumn from 'main/videoAssets/VideoTable/RestoreVideoColumn'
import { MenuInfo } from 'rc-menu/lib/interface'
import React, { ReactText } from 'react'
import { connect } from 'react-redux'
import { RouteProps, RouterProps, withRouter } from 'react-router'
import { compose } from 'recompose'
import { bindActionCreators, Dispatch } from 'redux'
import * as routes from 'router/routes'
import { showModalFlow } from 'store/modal/actions'
import { initialVideoSelector } from 'store/restoreVideoState/selectors'
import { RootState } from 'store/rootReducer'
import { setWindowLocationFlow } from 'store/router/actions'

type Props = StoreProps<typeof mapStateToProps, typeof mapDispatchToProps> &
  RouterProps &
  RouteProps

const NavBar: React.FC<Props> = ({
  history,
  location,
  setWindowLocation,
  initialVideo,
  showModal,
}) => {
  const { isAuthenticated, user, logout } = useAuth0()
  const [, onDoctorIdsChange] = React.useState<string[]>([])
  const [, onSearch] = React.useState('')
  const [, onFiltersChange] = React.useState<Record<
    string,
    ReactText[] | null
  > | null>(null)
  const video = JSON.parse(initialVideo)

  const [currentRoute, setCurrentRoute] = React.useState(getInitialRoute)
  const [selectedMainTrack, setSelectedMainTrack] = React.useState<
    'surgical' | 'radiology'
  >('surgical')

  React.useEffect(() => {
    setInitialWindowLocation()
    if (
      location!.pathname === routes.DICOM_ASSETS ||
      location!.pathname === routes.THREED_ASSETS
    ) {
      setSelectedMainTrack('radiology')
    } else {
      setSelectedMainTrack('surgical')
    }
  }, [location!.pathname])

  function getInitialRoute() {
    if (location && location.pathname !== '/login') {
      return location.pathname
    }
    return routes.RAW_DATA
  }

  function setInitialWindowLocation() {
    setWindowLocation(currentRoute)
  }

  function handleNavigation(e: MenuInfo) {
    history.push(e.key)
    setCurrentRoute(e.key)
  }

  function toggleTrack(track: 'surgical' | 'radiology') {
    setSelectedMainTrack(track)
    if (track === 'surgical') {
      setCurrentRoute(routes.MAIN)
      history.push(routes.MAIN)
    } else {
      setCurrentRoute(routes.DICOM_ASSETS)
      history.push(routes.DICOM_ASSETS)
    }
  }

  function renderTrackSelectionMenu() {
    return (
      <Menu mode="horizontal" selectedKeys={[selectedMainTrack]}>
        <MenuItem
          style={{
            height: '50px',
            padding: '12px 20px 0',
            color:
              selectedMainTrack === 'surgical' ? '#485D71' : 'rgb(72, 93, 113)',
            fontSize: '16px',
          }}
          fontWeight="bold"
          key="surgical"
          onClick={() => toggleTrack('surgical')}>
          Surgical AI Track
        </MenuItem>
        <MenuItem
          style={{
            height: '50px',
            padding: '12px 20px 0',
            color:
              selectedMainTrack === 'radiology'
                ? '#485D71'
                : 'rgb(72, 93, 113)',
            fontSize: '16px',
          }}
          fontWeight="bold"
          key="radiology"
          onClick={() => toggleTrack('radiology')}>
          Radiology AI Track
        </MenuItem>
      </Menu>
    )
  }

  function renderNavOptionsMenu() {
    return (
      <Menu
        disabledOverflow={true}
        onClick={handleNavigation}
        selectedKeys={[currentRoute]}
        mode="horizontal">
        {selectedMainTrack === 'surgical' && (
          <>
            <MenuItem
              style={{
                height: '50px',
                padding: '12px 20px 0',
                color: 'rgb(72, 93, 113)',
                fontSize: '16px',
              }}
              fontWeight="bold"
              key={routes.MAIN || routes.RAW_DATA}>
              Data Extraction
            </MenuItem>
            <MenuItem
              style={{
                height: '50px',
                padding: '12px 20px 0',
                color: 'rgb(72, 93, 113)',
                fontSize: '16px',
              }}
              fontWeight="bold"
              key={routes.SME_VIDEO_ASSETS}>
              SME Video Assets
            </MenuItem>
            <MenuItem
              style={{
                height: '50px',
                padding: '12px 20px 0',
                color: 'rgb(72, 93, 113)',
                fontSize: '16px',
              }}
              fontWeight="bold"
              key={routes.ANALYTICS}>
              Source and Class Distribution
            </MenuItem>
            <MenuItem
              style={{
                height: '50px',
                padding: '12px 20px 0',
                color: 'rgb(72, 93, 113)',
                fontSize: '16px',
              }}
              fontWeight="bold"
              key={routes.QA}>
              Authorship and QA
            </MenuItem>
            <MenuItem
              style={{
                height: '50px',
                padding: '12px 20px 0',
                color: 'rgb(72, 93, 113)',
                fontSize: '16px',
              }}
              fontWeight="bold"
              key={routes.DATA_LINEAGE}>
              Provenance
            </MenuItem>
          </>
        )}
        {selectedMainTrack === 'radiology' && (
          <>
            <MenuItem
              style={{
                height: '50px',
                padding: '12px 20px 0',
                color: 'rgb(72, 93, 113)',
                fontSize: '16px',
              }}
              fontWeight="bold"
              key={routes.DICOM_ASSETS}>
              Dicom Assets
            </MenuItem>
            <MenuItem
              style={{
                height: '50px',
                padding: '12px 20px 0',
                color: 'rgb(72, 93, 113)',
                fontSize: '16px',
              }}
              fontWeight="bold"
              key={routes.THREED_ASSETS}>
              3D Assets
            </MenuItem>
          </>
        )}
      </Menu>
    )
  }

  function renderDropdownOptions() {
    return (
      <Menu
        style={{
          margin: '5px',
        }}>
        <MenuItem>
          <RestoreVideoColumn
            video={video}
            onSearch={onSearch}
            onFiltersChange={onFiltersChange}
            onDoctorIdsChange={onDoctorIdsChange}
          />
        </MenuItem>
        <MenuItem
          onClick={() =>
            showModal('CREATE_UPLOADER_ACCOUNT_MODAL', { isOpen: true })
          }>
          Create uploader account
        </MenuItem>
        <Divider
          style={{
            margin: '0px',
            height: '1.2px',
            background: 'rgba(210, 210, 210)',
          }}
        />
        <MenuItem onClick={() => logout({ returnTo: window.location.origin })}>
          Logout
        </MenuItem>
      </Menu>
    )
  }

  function renderUserInfo() {
    return (
      <Stack center gutter={Spacing.EXTRA_SMALL}>
        <Text color={GREY}>Hi,</Text>
        <Text
          style={{
            paddingLeft: '5px',
            paddingRight: '5px',
          }}
          fontSize={16}
          fontWeight={600}
          color={GREY}>
          {user!.name}
        </Text>
        <Dropdown overlay={renderDropdownOptions}>
          <Icon
            icon="chevron-down"
            style={{ color: GREY, cursor: 'pointer' }}
          />
        </Dropdown>
      </Stack>
    )
  }

  return (
    <div className="navbar-box">
      <Stack justifyContent="space-between" fillParentWidth fillParentHeight>
        <Stack center fillParentHeight>
          {isAuthenticated && (
            <div>
              <Stack
                center
                fillParentWidth
                style={{ paddingLeft: 60, paddingRight: 75, paddingTop: 25 }}>
                <Logo
                  onClick={() => history.push(routes.MAIN)}
                  src={logo}
                  height={25}
                  style={{ cursor: 'pointer', position: 'relative' }}
                />
              </Stack>
            </div>
          )}
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ width: 'max-content' }}>
              {isAuthenticated && renderTrackSelectionMenu()}
            </div>
            <div style={{ alignSelf: 'flex-start' }}>
              {isAuthenticated && renderNavOptionsMenu()}
            </div>
          </div>
        </Stack>

        <div
          style={{
            marginLeft: 'auto',
            marginTop: 'calc(1.5% + 48px)',
          }}>
          {isAuthenticated && renderUserInfo()}
        </div>
      </Stack>
    </div>
  )
}

const mapStateToProps = (s: RootState) => ({
  initialVideo: initialVideoSelector(s),
})

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      showModal: showModalFlow,
      setWindowLocation: setWindowLocationFlow,
    },
    dispatch,
  )

export default compose<Props, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(NavBar)
