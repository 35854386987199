import { Icon } from '@blueprintjs/core'
import { BLACK, GREEN } from 'common/colors'
import { Spacing } from 'common/stylings'
import Modal from 'components/Modal'
import Stack from 'components/Stack'
import Text from 'components/Typography'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'
import React from 'react'

interface Props {
  isOpen: boolean
  message: string
  hideModal: (...args: any) => void
}

const SuccessModal: React.FC<Props> = ({ isOpen, message, hideModal }) => {
  const sanitizedMessage = DOMPurify.sanitize(message, {
    ADD_ATTR: ['target', 'rel'],
  })

  return (
    <Modal
      visible={isOpen}
      onCancel={() => hideModal({ isOpen: false })}
      footer={null}>
      <Stack vertical gutter={Spacing.MEDIUM}>
        <Stack alignItems="center" gutter={Spacing.REGULAR}>
          <Icon
            icon="confirm"
            style={{ paddingTop: 5, color: GREEN, fontSize: 24 }}
          />
          <Text
            style={{ paddingLeft: 8 }}
            fontWeight="bold"
            color={BLACK}
            fontSize={18}>
            Success!
          </Text>
        </Stack>
        <div
          style={{
            paddingTop: 10,
            fontSize: '16px',
            paddingRight: 24,
            lineHeight: '1.6',
            color: BLACK,
          }}>
          {parse(sanitizedMessage)}
        </div>
      </Stack>
    </Modal>
  )
}

export default SuccessModal
